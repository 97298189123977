:root {
  --primary-color: #4263e2;
  --secondary-color: #f5f6f8;
  --blue-tint: #f3f2ff;
  --light-blue: #6984ec;
  --dark-blue-tint: #e3e4ef;
  --dim-text: #6a6a6a;
  --light-gray: #dddddd;
  --dark-gray: #444444;
  --light-black: #222222;
}

html {
  /* overflow: hidden;
  height: 100%; */
  /* background: linear-gradient(
    185deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(245, 246, 248, 1) 80%
  ); */
}

body {
  font-family: "Manrope", sans-serif !important;
  margin: 0px;
  overflow: auto;
  height: 100% !important;
  width: 100%;
  overscroll-behavior-y: none;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Manrope", sans-serif;
}

@font-face {
  font-family: "Montserrat-Regular";
  src: local("Montserrat"),
    url("../assets/fonts/Montserrat-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat-Medium";
  src: local("Montserrat"),
    url("../assets/fonts/Montserrat-Medium.ttf") format("truetype");
}

.fullscreen {
  min-height: calc(100vh - 90px);
  padding-bottom: 1rem;
}

.dim-text {
  color: var(--dim-text);
}

.link {
  text-decoration: none;
  font-weight: 500;
  color: var(--primary-color);
  border-bottom: 1px solid transparent;
  position: relative;
}
.link:hover {
  /* border-bottom: 1px solid var(--primary-color); */
}
.link:after {
  content: "»";
  opacity: 0;
  position: absolute;
  right: 0px;
  transition: 0.5s;
}
.link:hover:after {
  opacity: 1;
  right: -14px;
}

.animate-hue {
  animation: hueAnimation infinite alternate-reverse 2.5s ease-in-out;
}

@keyframes hueAnimation {
  from {
    opacity: 1;
    transform: scale(1.25);
  }
  to {
    opacity: 0.75;
    transform: scale(1);
  }
}

.error-input-animation {
  transition: box-shadow 0.5s;
  animation: shake 0.2s ease-in-out 0s 1;
  box-shadow: 0 0 0.3em var(--dim-text);
}

@keyframes shake {
  0% {
    margin-left: 0;
  }
  25% {
    margin-left: 5px;
  }
  75% {
    margin-left: -5px;
  }
  100% {
    margin-left: 0;
  }
}

.custom-scroll {
  scrollbar-color: var(--primary-color) var(--secondary-color);
  scrollbar-width: thin;
  padding-right: 0.5rem;
}
.custom-scroll::-webkit-scrollbar {
  width: 4px;
  margin: 2px;
}
.custom-scroll::-webkit-scrollbar-track {
  background-color: transparent;
  height: 2px;
}
.custom-scroll::-webkit-scrollbar-thumb {
  border-radius: 0.1rem;
  height: 2px;
  background-color: #dddddd;
}

.horizontal-scroll {
  scrollbar-color: var(--primary-color) var(--secondary-color);
  scrollbar-width: thin;
}
.horizontal-scroll::-webkit-scrollbar {
  height: 5px;
}
.horizontal-scroll::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  background-color: gray;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.table-faded-effect {
  position: absolute;
  right: -5px;
  top: 0;
  height: 100%;
  width: 50px;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1) 95%
  );
  filter: blur(2px);
  z-index: 1;
}
